@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"); */
@font-face {
    font-family: "helvetica";
    src: url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("woff"), url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("opentype"), url("https://candyfonts.com/wp-data/2018/10/26/11538/HELR45W.ttf") format("truetype");
}

@font-face {
    font-family: 'Buffalo';
    src: url('../src/Assets/fonts/Buffalo-Regular.otf') format('opentype');
}

*:focus {
    outline: none !important;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
    background-color: #f2f5fb;
}

.rmdp-container .custom-calendar {
    padding: 5px;
}

.rmdp-container div:nth-child(0) {
    left: 33% !important;
}

.rmdp-container .rmdp-day-picker {
    flex-direction: column !important;
}

@media (min-width: 768px) {
    .rmdp-container .custom-calendar {
        padding: 10px;
    }
    .rmdp-container div:nth-child(0) {
        left: 0 !important;
    }
    .rmdp-container .rmdp-day-picker {
        flex-direction: row !important;
    }
}

@media (min-width: 1024px) {
    .rmdp-container div:nth-child(0) {
        left: 0 !important;
    }
    .rmdp-container .rmdp-day-picker {
        flex-direction: row !important;
    }
}

.custom-input:focus {
    outline: none;
}

.custom-input {
    background-color: "#F2F5FB";
    height: "30px";
    border: "none";
    font-size: "14px";
    padding: "10";
    width: "600px";
}

.custom-input-date {
    font-size: "14px";
}

.style {
    background-color: #f2f5fb !important;
    border: none !important;
    font-size: 1rem !important;
}

.style:focus {
    border: none !important;
    outline: none !important;
}

.css-8t49rw-MuiStepConnector-line {
    border-left-width: 0;
    border: none !important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
}

.modal {
    top: -2rem;
    z-index: 100;
    background: #fff;
    position: relative;
    margin: auto;
    border-radius: 5px;
    max-width: 750px;
    max-height: 700px;
    width: 90%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid black;
    padding: 10px 40px;
    background: #060b26;
    color: white;
    width: 100vw;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 40px;
    background: #060b26;
    color: white;
}

.modal-footer-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    border: 2px solid wheat;
}

.modal-footer>.modal-footer-button>button {
    background: white;
    color: #060b26;
    font-size: 0.9rem;
    font-weight: 600;
    width: 100px;
    padding: 5px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.modal-close-button {
    font-size: 1.5rem;
    font-weight: 800;
    color: white;
    cursor: pointer;
    border: none;
    background: transparent;
}

.modal-body {
    padding: 1rem 2rem 1.5rem;
}

#loader {
    height: 148px;
    width: 148px;
    position: relative;
}

#loader div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

#loader div>svg>path {
    animation: loader 1.6s linear infinite;
}

@keyframes loader {
    0% {
        fill: #0B2775;
    }
    100% {
        fill: #CBE1F7;
    }
}

.sigCanvas {
  height: 200px;
  margin: 0;
}