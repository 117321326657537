.App {
  text-align: center;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica" !important;
  width: 100vw;
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
p ,h1,h2,h3,a, button{
  margin-bottom: 0 !important;
  font-family: "Helvetica" !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-input {
  background-color: #F2F5FB;
  height: 35px;
  top:4px;
  width:100%;
  font-size: 20px;
}

.custom-input:focus {
  outline: none;
}

.custom-input-2 {
  border-radius: 5px;
  border: 1px #0B2775 solid;
  padding: 4px 12px;
  background-color: #F2F5FB;
  height: 30px;
  width: 100px;
  text-align:center;
}

.custom-input-2:focus {
  outline: none;
}

.MuiAutocomplete-option {
  font-family:"Helvetica";
}

.list-teacher::-webkit-scrollbar {
  visibility:hidden; 
  font-family:"Helvetica"}
.list-teacher::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
  padding: 2px;
}

:root {
  --primary:  #0B2775;
  --secondary: #0B2775;
}

/* Scrollbar styles */

/* Firefox */
.scroll {
  scrollbar-width: thin;
  scrollbar-color: inherit;
}

/* Chrome, Edge, and Safari */
.scroll::-webkit-scrollbar {
  width: 6px;
}

.scroll::-webkit-scrollbar-track {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 2px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #0B2775;
  cursor: pointer;
  border-radius: 20px;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  visibility:hidden;

}
.ant-picker-clear > * {
    vertical-align: top;
    display: none;
}
    .ant-picker-clear:hover > * {
    vertical-align: top;
    display: none;
}

.anticon svg {
    display: none;
}

.ant-picker.ant-picker-borderless{
width:100%
}
.anticon .anticon-close-circle{
display:none,

}
.MuiAutocomplete-noOptions {
  font-family: "Helvetica"
}

.last-step-height {
  height: calc(100vh + 200px);
}

.myAnimation {
  animation-name: myAnimation;
  animation-duration: 5s;
}

.react-datepicker__time-container {
  /* border:2px solid red; */
  /* width: 100px; */
  font-size: 16px;
  /* padding: 0.1rem; */
  /* margin-left: 10; */
}

