 .custom-input:focus {
  outline: none;
  /* font-family:Nunito; */
  
}
 ..css-6hp17o-MuiList-root-MuiMenu-list{
  background: #F2F5FB !important;
}

@media (min-width: 768px) {
  ..css-6hp17o-MuiList-root-MuiMenu-list {
  background: #F2F5FB !important;
}  }
  
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  background: #F2F5FB !important;
  padding: .5rem;
}
